import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_base_form_app = _resolveComponent("base-form-app")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    onShow: _ctx.setup,
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validationErrors = {})),
    title: _ctx.title,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value) = $event)),
    onSave: _ctx.submit
  }, {
    default: _withCtx(() => [
      (_ctx.type)
        ? (_openBlock(), _createBlock(_component_base_form_app, {
            key: 0,
            loading: _ctx.loading,
            "onUpdate:loading": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.loading) = $event)),
            withSaveButtonEvent: false
          }, {
            default: _withCtx(() => [
              (_ctx.selectionParent)
                ? (_openBlock(), _createBlock(_component_base_form_group, {
                    key: 0,
                    readonly: "",
                    "input-type": "text",
                    label: _ctx.trans('layout.selection.parent'),
                    modelValue: _ctx.selectionParent.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectionParent.name) = $event))
                  }, null, 8, ["label", "modelValue"]))
                : _createCommentVNode("", true),
              _createVNode(_component_base_form_group, {
                "input-type": "text",
                label: _ctx.trans('layout.selection.name'),
                modelValue: _ctx.form.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
                invalidMessage: _ctx.validationErrors.name
              }, null, 8, ["label", "modelValue", "invalidMessage"])
            ]),
            _: 1
          }, 8, ["loading"]))
        : (_openBlock(), _createElementBlock("p", _hoisted_1, "The selected type is invalid."))
    ]),
    _: 1
  }, 8, ["onShow", "title", "modelValue", "onSave"]))
}