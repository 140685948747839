
import { DefineComponent, defineComponent, watch } from "vue";
import { SelectionData } from "@/models/selection";
import { helperMixin, selectionDataProp } from "@/mixins";
import CheckboxActionTool from "@/components/CheckboxActionTool.vue";

export default defineComponent({
  components: {
    CheckboxActionTool,
  },

  mixins: [helperMixin, selectionDataProp],

  emits: ["add-item", "edit", "delete"],

  props: {
    modelValue: {
      type: Array,
      default: [] as Array<any>,
    },

    parentIndex: {
      type: Number,
      default: 0,
      required: false,
    },
  },

  data() {
    return {
      isChecked: false,
      hide: false,
    };
  },

  computed: {
    availableToCRUD() {
      return !this.selectionData?.is_default || this.parentIndex < 2;
    },

    index() {
      return this.modelValue.indexOf(this.selectionData?.id);
    },
    isAlreadyChecked() {
      return this.index !== -1;
    },
    valuable() {
      return !this.childs.length;
    },
  },

  mounted() {
    this.setup();

    watch(() => this.isChecked, this.handleChange);
  },

  methods: {
    edit() {
      this.$emit("edit", this.selectionData?.id);
    },

    remove() {
      this.$emit("delete", {
        id: this.selectionData?.id,
        parentId: this.selectionData?.parent_id,
        name: this.selectionData?.name,
      });
    },

    addItem() {
      this.$emit("add-item", this.selectionData?.id);
    },

    setup() {
      this.$nextTick(() => {
        if (this.valuable) {
          if (this.isAlreadyChecked) {
            this.isChecked = true;

            !this.$parent.isChecked && this.$parent.recursiveChecked
              ? this.$parent.recursiveChecked()
              : "";
          }
        }

        if (this.selectionData?.parent_id) {
          this.hide = !this.isChecked && !this.$parent.isChecked;
        }
      });
    },

    childRefKey(child: SelectionData) {
      return `child-${child.id}`;
    },

    handleChange(isChecked: boolean) {
      if (this.childs.length) {
        this.recursiveHide();
      } else if (isChecked) {
        if (!this.isAlreadyChecked)
          this.modelValue.push(this.selectionData?.id);
      } else {
        this.modelValue.splice(this.index, 1);
      }
    },

    recursiveChecked() {
      if (this.isChecked) return;

      this.isChecked = true;

      this.handleChange(true);

      this.$nextTick(() => {
        if (this.$parent.recursiveChecked) this.$parent.recursiveChecked();
      });
    },

    recursiveHide() {
      for (let i = 0; i < this.childs.length; i++) {
        const refChild: DefineComponent = this.$refs[
          this.childRefKey(this.childs[i])
        ]?.[0] as DefineComponent;

        if (!refChild) continue;

        refChild.hide = !this.isChecked;

        if (!this.isChecked && refChild.isChecked) {
          refChild.isChecked = false;

          if (refChild.index !== -1) {
            this.modelValue.splice(refChild.index, 1);
          }
        }

        refChild.$nextTick(() => {
          if (refChild.childs.length) refChild.recursiveHide();
        });
      }
    },

    isChildChecked(childs: SelectionData[]) {
      for (let i = 0; i < childs.length; i++) {
        const refChild: DefineComponent = this.$refs[
          this.childRefKey(childs[i])
        ]?.[0] as DefineComponent;

        if (refChild.isChecked) {
          return true;
        }

        if (childs[i].recursive_childs) {
          return this.isChildChecked(
            childs[i].recursive_childs as SelectionData[]
          );
        }
      }
    },
  },

  watch: {
    selectionData() {
      this.$nextTick(() => {
        this.isChecked =
          this.index !== -1 ||
          (this.isChildChecked(this.childs) as unknown as boolean);
      });
    },
  },
});
