import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0680498b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "pl-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_action_tool = _resolveComponent("checkbox-action-tool")!
  const _component_requirement_checkbox = _resolveComponent("requirement-checkbox", true)!

  return (!_ctx.hide)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["uwu-tree", [_ctx.isChecked && _ctx.childs.length ? 'line' : '']])
      }, [
        (_ctx.selectionData)
          ? (_openBlock(), _createBlock(_component_checkbox_action_tool, {
              key: 0,
              class: _normalizeClass(["uwu-tree-checkbox", [!_ctx.selectionData?.parent_id ? 'root' : '']]),
              modelValue: _ctx.isChecked,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
              value: _ctx.selectionData.id,
              "with-edit": !_ctx.selectionData.is_default,
              "with-delete": !_ctx.selectionData.is_default,
              withActionTool: _ctx.availableToCRUD,
              onEdit: _ctx.edit,
              onDelete: _ctx.remove,
              onAddItem: _ctx.addItem
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.selectionData.name), 1)
              ]),
              _: 1
            }, 8, ["class", "modelValue", "value", "with-edit", "with-delete", "withActionTool", "onEdit", "onDelete", "onAddItem"]))
          : _createCommentVNode("", true),
        (_ctx.childs.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childs, (child, i) => {
                return (_openBlock(), _createBlock(_component_requirement_checkbox, {
                  key: i,
                  "selection-data": child,
                  ref_for: true,
                  ref: _ctx.childRefKey(child),
                  parentIndex: _ctx.parentIndex + 1,
                  modelValue: _ctx.modelValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue) = $event)),
                  onEdit: _cache[2] || (_cache[2] = (data) => _ctx.$emit('edit', data)),
                  onDelete: _cache[3] || (_cache[3] = (data) => _ctx.$emit('delete', data)),
                  onAddItem: _cache[4] || (_cache[4] = (data) => _ctx.$emit('add-item', data))
                }, null, 8, ["selection-data", "parentIndex", "modelValue"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}