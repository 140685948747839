import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
import { JobFilter, JobPaginate, JobSave } from "@/models/job";

class Job extends BaseResourceHttp<JobPaginate, JobSave, JobSave> {
    protected baseURL(): string {
        return "job";
    }

    public paginate(filter?: JobFilter) {
        return super.paginate({
            ...filter,
            position_in: filter?.position_in?.join(","),
        } as Omit<JobFilter, "position_in">);
    }
}

export default createInstance<Job>(Job);