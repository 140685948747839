
import { helperMixin, selectionDataHelper, uwuLoaderDelay } from "@/mixins";
import { defineComponent, PropType, watch } from "vue";
import selectionStore from "@/store/selection";
import { SelectionData } from "@/models/selection";
import SelectionDataList from "@/components/Job/SelectionDataList.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";

export default defineComponent({
  components: { SelectionDataList, CompanyLogo },
  mixins: [helperMixin, selectionDataHelper, uwuLoaderDelay],
  props: {
    fetchSelection: {
      type: Boolean,
      default: false
    },
    job: {
      type: Object as PropType<import("@/models/job").JobSave>,
      required: true
    }
  },

  data() {
    return {
      requirements: [] as Array<SelectionData>
    };
  },

  computed: {
    selectionStore,

    orderedRequirements() {
      return this.requirements.sort(this.compareSelectionOrder);
    },

    benefits() {
      return this.job.benefits
        ? this.job.benefits
            .map(
              id =>
                this.selectionStore.findById(
                  id,
                  this.selectionStore.state.job_benefit
                ) as SelectionData
            )
            .sort(this.compareSelectionOrder)
        : [];
    },

    positionName() {
      return this.selectionStore.findById(
        this.job.position_id,
        this.selectionStore.state.job_position
      )?.name;
    },

    currency() {
      return this.selectionStore.findById(
        this.job.salary_currency_id,
        this.selectionStore.state.currency
      )?.name;
    },

    salary() {
      return this.numberFormat(this.job.salary_rate);
    },

    salaryType() {
      return this.selectionStore.findById(
        this.job.salary_type_id,
        this.selectionStore.state.job_salary_type
      )?.name;
    },

    contractOnType() {
      return this.selectionStore.findById(
        this.job.contract_on_type_id,
        this.selectionStore.state.job_contract_type
      )?.name;
    },

    contractOffType() {
      return this.selectionStore.findById(
        this.job.contract_off_type_id,
        this.selectionStore.state.job_contract_type
      )?.name;
    }
  },
  async mounted() {
    if (this.fetchSelection) await this.selectionStore.fetch();

    watch(() => this.job.requirements, this.setupRequirements, {
      deep: true,
      immediate: true
    });
  },
  methods: {
    setupRequirements() {
      for (let i = 0; i != (this.job.requirements?.length || 0); i++) {
        const requirement = this.selectionStore.findById(
          this.job.requirements[i],
          this.selectionStore.state.job_requirement
        );

        if (!requirement) {
          continue;
        }

        this.pushIntoRequirement({ ...requirement });
      }

      if (!this.job?.requirements?.length) {
        this.requirements = []
      }
    },
    pushIntoRequirement(requirement: SelectionData): SelectionData | undefined {
      let parent: undefined | Array<SelectionData>;

      if (!requirement.parent_id) {
        parent = this.requirements;
      } else {
        let parentData = {
          ...this.selectionStore.findById(
            requirement.parent_id,
            this.selectionStore.state.job_requirement
          )
        } as SelectionData;

        if (parentData.id) {
          parentData.recursive_childs = [];
          parent = this.pushIntoRequirement(parentData)?.recursive_childs;
        }
      }

      let i = parent?.findIndex(d => d.id == requirement.id) ?? 0;

      if (parent) {
        if (i === -1) {
          i = parent.push(requirement) - 1;
        }

        return parent[i];
      }
    }
  }
});
