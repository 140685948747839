
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import selection from "@/http/selection";
import selectionStore from "@/store/selection";
import { baseModal, helperMixin, saveAndUpdate } from "@/mixins";
import { SelectionData, SelectionSave } from "@/models/selection";
import { defineComponent, PropType } from "vue";
import { SelectionType } from "@/enums";

export default defineComponent({
  components: { BaseModal, BaseFormApp, BaseFormGroup },
  mixins: [
    helperMixin,
    baseModal,
    saveAndUpdate<SelectionSave, SelectionData>(selection()),
  ],
  props: {
    type: {
      type: String as PropType<import("@/enums").SelectionType>,
      required: false
    },
    parentId: {
      default: null,
    },
    selectionId: {
      default: null,
    },
  },
  computed: {
    selectionStore,
    selectionParent() {
      return this.selectionStore.findById(
        this.form.parent_id,
        this.selectionStore.state[this.type as SelectionType]
      );
    },
    updateId() {
      return this.selectionId;
    },
    redirectAfterSubmit(): boolean {
      return false;
    },
  },
  methods: {
    afterSubmit(data: SelectionData) {
      let parent: Array<SelectionData> = [];

      if (!data.parent_id) {
        parent = this.selectionStore.state[this.type as SelectionType];
      } else if (this.selectionParent) {
        parent = this.selectionParent.recursive_childs =
          this.selectionParent.recursive_childs || [];
      }

      if (this.isUpdateState) {
        const i = parent.findIndex((d) => d.id == data.id);

        if (i !== -1) {
          parent[i] = data;
        }
      } else {
        parent.unshift(data);
      }

      this.$emit("update:modelValue", false);
    },
    setForm() {
      this.type ? (this.form.type = this.type) : "";
      this.form.parent_id = this.data?.parent_id || this.parentId;
      this.form.name = this.data?.name;
    },
  },
});
