
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import { saveAndUpdate, helperMixin, selectionSaveModal } from "@/mixins";
import { JobSave, JobDetail } from "@/models/job";
import { defineComponent } from "vue";
import jobHttp from "@/http/job";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import selectionStore from "@/store/selection";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import RequirementCheckbox from "@/components/Job/RequirementCheckbox.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import JobPoster from "@/components/Job/JobPoster.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { SelectionType } from "@/enums";
import ConfirmModal from "@/components/ConfirmModal.vue";
import CheckboxActionTool from "@/components/CheckboxActionTool.vue";
import { BaseItem } from "@/interfaces";
import SelectionSaveModal from "@/components/Selection/SelectionSaveModal.vue";
import authStore from "@/store/auth";

export default defineComponent({
  components: {
    BaseFormApp,
    BaseFormGroup,
    BaseCheckbox,
    RequirementCheckbox,
    TeleportToContentFooter,
    BaseButtonDefault,
    BaseModal,
    JobPoster,
    BaseButtonPrimary,
    ConfirmModal,
    CheckboxActionTool,
    SelectionSaveModal
  },

  mixins: [
    helperMixin,
    saveAndUpdate<JobSave, JobDetail>(jobHttp()),
    selectionSaveModal
  ],

  computed: {
    selectionStore,
    authStore,
    defaultContractType() {
      return this.selectionStore.state.job_contract_type[0];
    },
    contractDurations() {
      const arrs: Array<BaseItem> = [];

      for (let i = 0; i < 48; i++) {
        arrs.push({
          value: i + 1,
          text: i + 1 + " " + this.defaultContractType?.name
        });
      }

      return arrs;
    }
  },

  data() {
    return {
      SelectionType,
      showPreviewModal: false,
      showConfirmSubmitModal: false
    };
  },

  methods: {
    async processSubmit() {
      await this.submit();
      this.showConfirmSubmitModal = false;
    },

    confirmSubmit() {
      this.showConfirmSubmitModal = true;
    },

    onMounted() {
      return this.selectionStore.fetch();
    },

    setForm() {
      this.form.hiring_nationality_id = this.data?.hiring_nationality.id;
      this.form.purpose_id = this.data?.purpose.id;
      this.form.position_id = this.data?.position.id;

      this.form.join_asap = this.data?.join_asap;

      this.form.estimate_join_date = this.data?.estimate_join_date || null;

      this.form.contract_on_duration = this.data?.contract_on_duration || 1;
      this.form.contract_off_duration =
        this.data?.contract_off_duration || null;

      this.form.salary_type_id = this.data?.salary_type.id || "";

      this.form.salary_rate = this.numberFormat(
        ((this.data?.salary_rate as unknown) as string) || ""
      ) as string;

      this.form.salary_currency_id = this.data?.salary_currency.id || "";

      this.form.requirements =
        this.data?.requirements.map(requirement => requirement.id) || [];

      this.form.benefits = this.data?.benefits.map(benefit => benefit.id) || [];

      this.form.last_submission_date = this.data?.last_submission_date || "";

      this.form.note = this.data?.note || "";
    }
  },

  watch: {
    defaultContractType: {
      immediate: true,
      deep: true,
      handler(val) {
        this.form.contract_on_type_id = val?.id;
        this.form.contract_off_type_id = val?.id;
      }
    }
  }
});
